const ModuloFiltroEnum = Object.freeze({
    PROCEDIMENTOS: 'PROCEDIMENTOS',
    CLIENTES: 'CLIENTES',
    RISCOS: 'RISCOS',
    ATIVIDADES_ESPECIFICAS: 'ATIVIDADES_ESPECIFICAS',
    PERGUNTA_SESMT: 'PERGUNTA_SESMT',
    QUESTIONARIO_SESMT: 'QUESTIONARIO_SESMT',
    MEDIDA_CONTROLE: 'MEDIDA_CONTROLE',
    ADMISSAO_DIGITAL: 'ADMISSAO_DIGITAL'
});

export default ModuloFiltroEnum;
