<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="gestaosesmt_cadastros_medida_controle"
        newPath="/medida-adm-engenharia/new"
        editPath="/medida-adm-engenharia/"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="Medidas de controle"
        tooltip="Registro de medidas de controle"
        descricao="FILTRO PADRÃO MEDIDA CONTROLE"
        nomeTelaDoManual="medida-adm-engenharia-list"
        origemHistoricoAcao="/medida-adm-engenharia"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="medida.id" header="Cód." />
            <Column field="descricao" :sortable="true" header="Descrição" />
            <Column field="tipo" :sortable="true" header="Tipo" />
            <Column field="created_at" :sortable="true" sortField="medida.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreate?.name }} </small>
                </template>
            </Column>
            <Column field="updated_at" :sortable="true" sortField="medida.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userUpdate?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany } from '../../services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';

export default {
    data() {
        return {
            record: {}
        };
    },
    created() {
        this.service = new SesmtService('/medida-adm-engenharia');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar medida de controle' : 'Adicionar Medida de controle';
        },
        tenant() {
            return getCurrentCompany();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },

    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        }
    }
};
</script>
